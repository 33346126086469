const { defaultsDeep } = require('lodash');

require('./bootstrap');

$(document).ready(function() {
    $("#flexCheckDefault").click(function() {
      if ($(this).is(":checked")) {
        $("#campos_contacto").hide();
      } else {
        $("#campos_contacto").show();
      }
    });


    $("#button-copy").click(function() {

      var input = $("#input-copy").select();

      try {  
        var successful = document.execCommand('copy');  
        
      } catch(err) {  
        console.error('Unable to copy'); 
      }	

    });

});